var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hideButon)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('button-component',{staticClass:"button-icon",attrs:{"content-btn":"","variant":"danger","icon-btn":"Trash2Icon","disabled":!_vm.showBtnMultiDelete},on:{"click":_vm.deleteItems}})],1),_c('div',{staticClass:"header-action"},[_c('search',{staticClass:"search-input",on:{"change":_vm.search}}),_c('button-component',{staticClass:"ml-1",attrs:{"content-btn":"Thêm nhóm người dùng","variant":"primary","icon-btn":"PlusIcon"},on:{"click":_vm.handleModalGroupUser}})],1)]):_vm._e(),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataListUserGroup || [],"select-options":{
      enabled: _vm.hideButon,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label ==='Code')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" Mã NHÓM NGƯỜI DÙNG ")]):(props.column.label ==='userTypeName')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" TÊN NHÓM ")]):(props.column.label ==='registeredDate')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" NGÀY GHI DANH ")]):(props.column.label ==='ActionFunction')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" CHỨC NĂNG ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.$t(props.row.code)))])])],1):(props.column.field === 'userTypeName')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])]):(props.column.field === 'registeredDate')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("formatDateToDDMM")(props.row.dateRegister)))])]):(props.column.field === ("" + (_vm.hideButon ?'actionFunction':'')))?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('modal-user-group',{attrs:{"idModalGroupUser":"modalGroup","dataListAddUserGroup":_vm.dataListAddUserGroup,"totalRecord":_vm.totalRecordNotRegister,"queryGetList":_vm.queryGetList},on:{"addGroupUser":_vm.addGroupUserHandle,"search":_vm.searchUsers,"pageChangeModal":_vm.pageChangeModal}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }