<template>
  <div>
    <b-form class="content-form">
      <b-row>
        <b-col
          md="6"
          xl="4"
        >
          <!-- input birth-day -->
          <b-form-group>
            <label for="InputHelp">Ngày sinh</label>
            <date-time-picker
              v-model="inforUser.birthDayString"
              :disabledInput="true"
              :disabled="disableProp"
              @input="() => {}"
            />
          </b-form-group>
        </b-col>

        <!-- Số điện thoại-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Số điện thoại</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.phoneNumber"
              type="number"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>

        <!-- Địa chỉ-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Địa chỉ</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.address"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Quê quán-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Quê quán</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.placeOfBirth"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>

        <!-- Chức vụ-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Chức vụ</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.regency"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>

        <!-- Học hàm-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Học hàm</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.academicDegree"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Học vị-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Học vị</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.academicRank"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>

        <!-- Cơ cấu tổ chức-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group>
            <label for="InputHelp">Cơ cấu tổ chức</label>
            <organization-select
              v-model="inforUser.idOrganizationStructure"
              :disableProp="disableProp"
            />
          </b-form-group>
        </b-col>

        <!-- Vị trí công việc-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group>
            <label for="InputHelp">Vị trí công việc</label>
            <v-select
              v-model="inforUser.positionId"
              :reduce="positionId => positionId.id"
              label="name"
              placeholder="Lựa chọn vị trí công việc"
              :options="dataListPosition || []"
              :disabled="disableProp"
            >
              <template
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
              </template>
              <template #selected-option-container="{ option }">
                <div class="vs__selected">
                  {{ option.name }}
                </div>
              </template>
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Mã số thuế-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Mã số thuế</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.taxNumber"
              type="number"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>

        <!-- Số CMND-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Số CMND</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.identityCardNumber"
              type="number"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>

        <!-- Ngày cấp-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group>
            <label for="InputHelp">Ngày cấp</label>
            <date-time-picker
              v-model="inforUser.dateIssuedString"
              :disabledInput="true"
              :disabled="disableProp"
              @input="() => {}"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Nơi cấp-->
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Nơi cấp</label>
            <b-form-input
              id="basicInput"
              v-model="inforUser.locationIssued"
              :disabled="disableProp"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import OrganizationSelect from '../components/OrganizationSelect.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    DateTimePicker,
    OrganizationSelect,
  },
  props: {
    disableProp: {
      type: Boolean,
      default: false,
    },
    inforUser: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      dataListPosition: [],
    }
  },
  created() {
    this.fetchListPosition()
  },
  methods: {
    // Lấy danh sách vị trí công việc
    async fetchListPosition() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_POSITION)
      this.dataListPosition = data.data
    },
  },
}
</script>

<style>

</style>
