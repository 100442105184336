<template>
  <b-modal
    :id="idModalGroupUser"
    size="lg"
    title="Thêm nhóm người dùng"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="addGroupUser"
  >
    <div class="header-action">
      <search @change="search" />
    </div>
    <vue-good-table
      style-class="vgt-table"
      :columns="columnModal"
      :rows="dataListAddUserGroup || []"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTableModal"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label ==='Code'"
          class="text-nowrap"
        >
          MÃ NHÓM
        </span>

        <span
          v-if="props.column.label ==='NameGroup'"
          class="text-nowrap"
        >
          TÊN NHÓM
        </span>

        <span
          v-if="props.column.label ==='Description'"
          class="text-nowrap"
        >
          MÔ TẢ
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            pill
            class="light-code-group"
          >
            <span class="text-name">{{ $t(props.row.code) }}</span>
          </b-badge>
        </span>
        <span v-if="props.column.field === 'nameGroup'">
          <span>{{ props.row.name }}</span>
        </span>
        <span v-else-if="props.column.field === 'description'">
          <span class="text-nowrap">{{ props.row.description }}</span>
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="queryGetList.pageNumber"
      @pageClick="pageChangeModal"
    />
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import {
  BMedia,
  BDropdown, BDropdownItem, BRow, BCol, BFormGroup, BBadge, BModal, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Search from '@/components/search/Search.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BMedia,
    Search,
    BBadge,
    BModal,
    BButton,
    vSelect,
  },
  props: {
    idModalGroupUser: {
      type: String,
      default: '',
    },
    dataListAddUserGroup: {
      type: Array,
      default: _ => [],
    },
    totalRecord: {
      type: Number,
      default: 0,
    },
    queryGetList: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      totalRecordModal: 0,
      columnModal: [
        {
          label: 'Code',
          field: 'code',
          sortable: false,
        },
        {
          label: 'NameGroup',
          field: 'nameGroup',
          sortable: false,
        },
        {
          label: 'Description',
          field: 'description',
          sortable: false,
        },
      ],
      listModel: [],
    }
  },
  methods: {

    // tìm kiếm
    search(val) {
      this.$emit('search', val)
    },

    // phân trang modal
    pageChangeModal(pageNumber, pageSize) {
      this.$emit('pageChangeModal', pageNumber, pageSize)
    },

    // lấy giá trị cần chọn
    selectRowTableModal(val) {
      this.listModel = []
      val.selectedRows.forEach(element => {
        const { id } = element
        this.listModel.push(id)
      })
    },

    // truyền dữ liệu qua handle Add
    addGroupUser(evt) {
      evt.preventDefault()
      if (this.listModel.length === 0) {
        const message = 'Chọn ít nhất 1 nhóm người dùng'
        this.$root.$bvToast.toast(message, {
          title: 'Thông báo', variant: 'warning', toaster: this.$toastPosition, solid: true,
        })
      } else {
        this.$emit('addGroupUser', this.listModel)
      }
    },
  },
}
</script>
<style lang="scss">
.light-code-group {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
}
</style>
