<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container">
      <validation-observer ref="tabAccount">
        <list-user
          id="user-add"
          class="tab-pane"
          :class="{'active':activeItem==='trash-bin-user'}"
        />

        <list-user-group
          id="user-add"
          class="tab-pane"
          :class="{'active':activeItem==='trash-bin-group'}"
        />
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BButton,
}
  from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import ListUser from './tab-list-detail/ListUser.vue'
import ListUserGroup from './tab-list-detail/ListUserGroup.vue'

export default {
  components: {
    MyTabs,
    ValidationObserver,
    ValidationProvider,
    BButton,
    ListUser,
    ListUserGroup,
  },
  data() {
    return {
      confirmModalId: 'add',
      activeItem: 'trash-bin-user',
      listTabs: [
        {
          key: 'trash-bin-user',
          title: 'Danh sách người dùng',
          icon: 'UserIcon',
          isRendered: false,
        },
        {
          key: 'trash-bin-group',
          title: 'Danh sách nhóm người dùng',
          icon: 'UsersIcon',
          isDisabled: false,
        },
      ],
    }
  },
  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },

    backUser() {
      this.$router.push({ name: 'list-user' })
    },
    show() {
      this.$bvModal.show(this.confirmModalId)
    },
  },
}
</script>

<style scoped>
.page-container{
  padding: 0px;
}
</style>
