<template>
  <div>
    <b-modal
      :id="id"
      :hide-footer="true"
      centered
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      title="Xem chi tiết nhóm người dùng"
    >
      <div class="form-input">
        <validation-observer ref="rule">
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputCode"
              >Mã nhóm người dùng <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="customCodeVali"
              >
                <b-form-input
                  id="basicInputCode"
                  v-model=" codeValue "
                  :state="errors.length > 0 ? false:null"
                  disabled
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputName"
              >Tên nhóm người dùng <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="customNameVali"
              >
                <b-form-input
                  id="basicInputName"
                  v-model=" nameValue "
                  :state="errors.length > 0 ? false:null"
                  disabled
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div>
            <label for="textarea-default">Mô tả</label>
            <b-form-textarea
              id="textarea-default"
              v-model="descriptionValue"
              disabled
              placeholder=""
              rows="3"
              no-resize
            />
          </div>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import {
  BCardText, BButton, BFormGroup, BForm, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BCol,
    BFormInput,
    BFormTextarea,
    ToastificationContent,
  },
  props: {
    idDetailGroup: {
      type: String,
      default: 'sam',
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: 'confirm-modal',
    },
    // nếu là thông báo không có nút cancel
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      required,
      checkName: false,
      checkCode: false,
      nameValue: '',
      codeValue: '',
      descriptionValue: '',
      customCodeVali: {
        required: 'Mã loại hình doanh nghiệp là bắt buộc',
      },
      customNameVali: {
        required: 'Tên loại hình doanh nghiệp là bắt buộc',
      },
      userTypeId: '',
      dataComboboxUserType: [],
    }
  },
  computed: {
    ...mapState('typeOfBusiness', ['dataEdit', 'alertAddUserGroup']),
  },
  watch: {
    async idDetailGroup(val) {
      if (val) {
        const dataUserGroup = await this.fetchUserGroupToId(val)
        this.nameValue = dataUserGroup.name
        this.codeValue = dataUserGroup.code
        this.descriptionValue = dataUserGroup.description
      }
    },
  },
  methods: {
    ...mapActions('userGroup', ['fetchUserGroupToId']),
  },
}
</script>
<style scoped>
.form-input{
 padding-bottom: 20px;
}
</style>
