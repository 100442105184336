<template>
  <div class="page-container-table">
    <!-- ==========================================================Bắt đầu breadcrumb-------------------------------- -->
    <!-- ==========================================================Bắt đầu header-------------------------------- -->

    <div class="d-flex justify-content-between button-head">
      <div class="d-flex">
        <!--=============== nút Xóa file trên cùng================= -->
        <button-component
          v-b-tooltip.hover.top="'Xóa nhiều'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="btn-icon-trust ml-2 mb-1"
          icon-btn="Trash2Icon"
          :disabled="!showBtnMultiDelete"
          @click="deleteMore()"
        />
        <button-component
          v-b-tooltip.hover.top="'Hồi lại'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          for="file"
          type="file"
          variant="primary"
          icon-btn="RotateCcwIcon"
          class="btn-icon-trust mb-1 ml-1"
          :disabled="!showBtnMultiDelete"
          @click="restoreMore()"
        />
      </div>
      <!--===========================Kết thúc phần nút trên cùng bên trái================= -->
      <!--========================== Bắt đầu phần tìm kiếm trên cùng bên phải================= -->
      <div>
        <!--========================================= Ô tìm kiếm ================================= -->
        <search
          class="search-input mb-1 mr-2"
          @change="search"
        />
      </div>
    </div>
    <!-- ==========================================================Phần bảng-------------------------------- -->
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        setCurrentPage: urlQuery.pageNumber,
        perPage: urlQuery.pageSize,
      }"
      :sort-options="{
        enabled: false,
        initialSortBy: [
          {field: 'name', type: 'asc'},
          {field: 'code', type: 'desc'}
        ],
      }"
      :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
      @on-selected-rows-change="selectRowTable"
    >
      <!--=================== chỉnh sửa phần chức năng trong bảng==================== -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'cn'">
          <span>
            <feather-icon
              v-b-tooltip.hover.top="'Xem chi tiết'"
              icon="EyeIcon"
              class="table__chucnang__icon"
              size="18"
              style="margin-right: 14px"
              @click="handleDetailUser(props.row.id)"
            /><feather-icon
              v-b-tooltip.hover.top="'Khôi phục'"
              icon="RotateCcwIcon"
              class="table__chucnang__icon"
              size="18"
              @click="handleClickRestoreButton(props.row.id)"
            /><feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="table__chucnang__icon"
              style="margin-left: 14px"
              @click="handleClickDeleteButton(props.row.id)"
            /></span>
        </span>
        <span v-if="props.column.field === 'userDelete'">
          <span class="text-nowrap">{{ props.row.userDelete }}</span>
        </span>
        <span v-if="props.column.field === 'userName'">
          <span class="text-nowrap">{{ props.row.userName }}</span>
        </span>
        <span v-if="props.column.field === 'dateDelete'">
          <span class="text-nowrap">{{ props.row.dateDelete | formatDateToDDMM }}</span>
        </span>
      </template>
      />
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />
    <!-- ==========================================================Kết thúc header-------------------------------- -->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      :title="titleCustom"
      @accept="restoreToTrash"
    />
    <confirm-modal
      :id="confirmModalTrashId"
      :title="titleCustom"
      :content="modalContent"
      @accept="deleteTrash"
    />
    <modal-detail-user
      :id="modalDetailUser"
      :idEdit="idEdit"
    />

    <!-- ========================================================== kết thúc Phần bảng-------------------------------- -->
  </div>
</template>

<script>
// =====================================================Phân import file ======================================//
import {
  BPagination,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBreadcrumb,
  BBreadcrumbItem,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { formatDateToDDMM } from '@core/utils/filter'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalDetailUser from '../components/ModalDetailUser.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ButtonComponent,
    VBTooltip,
    // eslint-disable-next-line vue/no-unused-components
    BBreadcrumb,
    BBreadcrumbItem,
    VueGoodTable,
    MyPagination,
    Search,
    ConfirmModal,
    ModalDetailUser,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      modalDetailUser: 'userDetail',
      idEdit: '',
      titleCustom: '',
      selectTable: [],
      deleteIds: [],
      showBtnMultiDelete: false,
      confirmModalId: 'confirm-modal-restore',
      confirmModalTrashId: 'confirm-modal-trash-users',
      restoreID: '',
      deleteID: '',
      modalContent: '',
      totalRecord: 0,
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Tài khoản xóa',
          field: 'userDelete',
        },
        {
          label: 'Tài khoản bị xóa',
          field: 'userName',
        },
        {
          label: 'Thời gian xóa',
          field: 'dateDelete',
        },
        {
          label: 'Chức năng',
          field: 'cn',
          tdClass: 'table__tool',
          thClass: 'table__tool',
          width: '200px',
        },
      ],
      rows: [
      ],
    }
  },
  async created() {
    await this.fetchDatatable()
  },
  methods: {
    // =========================================action api================================================
    // lấy bảng người dùng có phân trang
    // eslint-disable-next-line consistent-return
    async doFetchListTrash(payload) {
      try {
        const fetchListTrashData = await axiosApiInstance.get(ConstantsApi.FETCH_LIST_TRASH, { params: payload })
        if (fetchListTrashData.status === 200) {
          return fetchListTrashData.data
        }
      } catch (e) {
        return null
      }
    },
    // khôi phục người dùng
    // eslint-disable-next-line consistent-return
    async doRestoreToTrash(data) {
      try {
        this.$showAllPageLoading()
        const fetchListTrashData = await axiosApiInstance.post(ConstantsApi.RESTORE_TO_TRASH, data)
        if (fetchListTrashData.status === 200) {
          this.$hideAllPageLoading()
          return {
            oke: true,
            message: 'Khôi phục thành công',
          }
        }
      } catch (e) {
        this.$hideAllPageLoading()
        return {
          oke: false,
          message: 'Khôi phục thất bại',
        }
      }
    },
    // Xử lý khi ấn nút phân trang
    // =================================================Phân lấy dữ liệu bảng ===========================
    async fetchDatatable() {
      this.$showLoading()
      const data = await this.doFetchListTrash(this.urlQuery)
      this.rows = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },
    // ===============================================Phân phân trang ==============================
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDatatable()
    },
    // ==============================================phần seach ============================================
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fetchDatatable()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.fetchDatatable()
      }
    },

    //= ==========================================select table ==========================================//
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // ======================================== ấn nút restore ======================================//
    async handleClickRestoreButton(id) {
      this.modalContent = 'Bạn có muốn khôi phục không!'
      this.titleCustom = 'Khôi phục'
      this.deleteIds = []
      this.deleteIds.push(id)
      this.$bvModal.show(this.confirmModalId)
    },

    async restoreMore() {
      this.modalContent = 'Bạn có muốn khôi phục các mục đã chọn không!'
      this.titleCustom = 'Khôi phục'
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.$bvModal.show(this.confirmModalId)
    },
    async restoreToTrash() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_TO_TRASH, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Khôi phục thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDatatable()
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Khôi phục không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async deleteTrash() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.RESTORE_TO_TRASH, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDatatable()
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // ============================================Ấn nút xóa ===========================================//
    handleClickDeleteButton(id) {
      this.modalContent = 'Bạn có muốn xóa vĩnh viễn không!'
      this.titleCustom = 'Xóa'
      this.deleteIds = []
      this.deleteIds.push(id)
      this.$bvModal.show(this.confirmModalTrashId)
    },
    async deleteMore() {
      this.titleCustom = 'Xóa'
      this.modalContent = 'Bạn có muốn xóa vĩnh viễn các mục đã chọn không!'
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.$bvModal.show(this.confirmModalTrashId)
    },
    // ================= ấn nút chi tiết ========================
    handleDetailUser(id) {
      this.idEdit = id
      this.$bvModal.show(this.modalDetailUser)
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table__tool{
  width: 150px;
  text-align: center;
}

@media (max-width: 425px) {
  .button-head {
    flex-direction: column;
  }
  // .search-input {
  //   width: unset !important;
  //   margin-left: 1.5rem;
  // }
}
</style>
