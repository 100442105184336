<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div class="page-container">
      <validation-observer ref="tabAccount">
        <tab-info-obligatoory
          id="user-add"
          ref="info"
          :accountInformation="accountInformation"
          :isEdit="isEdit"
          class="tab-pane"
          :class="{'active':activeItem==='user-edit-tab-account'}"
          :disableProp="disableProp"
        />

        <tab-info-user
          id="user-add"
          ref="user"
          :inforUser="inforUser"
          class="tab-pane"
          :class="{'active':activeItem==='user-edit-tab-information'}"
          :disableProp="disableProp"
        />

        <tab-user-group
          id="user-add"
          ref="group"
          :idEdit="idEdit"
          class="tab-pane"
          :class="{'active':activeItem==='user-edit-tab-users-information'}"
          :hideButon="hideButon"
        />
      </validation-observer>

      <div
        v-if="hideButon"
        class="mt-2"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hadnleCreate($event)"
        >
          Lưu lại
        </b-button>
        <b-button
          v-if="typeCheckRole !== 'info'"
          variant="outline-primary"
          type="reset"
          @click="backUser"
        >
          Quay lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
}
  from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import TabInfoObligatoory from './tabs-create/TabInfoObligatory.vue'
import TabInfoUser from './tabs-create/TabInfoUser.vue'
import TabUserGroup from './tabs-create/TabUserGroup.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    MyTabs,
    TabInfoObligatoory,
    TabInfoUser,
    TabUserGroup,
    ValidationObserver,
    ValidationProvider,
    BButton,
  },
  props: {
    idEdit: {
      type: String,
      default: '',
    },
    hideButon: {
      type: Boolean,
      default: true,
    },
    disableProp: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      required,
      inforUser: {
        birthDayString: '',
        phoneNumber: '',
        address: '',
        placeOfBirth: '',
        academicDegree: '',
        academicRank: '',
        idOrganizationStructure: '',
        identityCardNumber: '',
        dateIssuedString: '',
        regency: '',
        locationIssued: '',
        taxNumber: '',
        positionId: '',
        twoFactorEnabled: false,
      },
      routeNamePre: localStorage.getItem('route-name'),
      accountInformation: {
        avatar: '',
        code: '',
        gender: true,
        email: 'user@example.com',
        name: '',
        userName: '',
        password: '',
        userTypeId: null,
        rePassword: '',
        isDepartment: false,
      },
      activeItem: 'user-edit-tab-account',
      listTabs: [
        {
          key: 'user-edit-tab-account',
          title: 'Thông tin bắt buộc',
          icon: 'AlertCircleIcon',
          isRendered: false,
        },
        {
          key: 'user-edit-tab-information',
          title: 'Thông tin người dùng',
          icon: 'UserIcon',
          isDisabled: false,
        },
        {
          key: 'user-edit-tab-users-information',
          title: 'Nhóm người dùng',
          icon: 'UsersIcon',
          isDisabled: true,
        },
      ],
      userId: '',
      isEdit: true,
      dataUserId: {},
      typeCheckRole: '',
      isRender: true,
      userIdGetInfor: '',
      unitUserId: JSON.parse(localStorage.getItem('userData')).unitUserId,
      userIdLocalstorage: JSON.parse(localStorage.getItem('userData')).userId,
    }
  },
  watch: {
    async $route(newVal, oldVal) {
      if (newVal.name !== oldVal.name) {
        this.isRender = false
        this.typeCheckRole = (this.$route.params.type)
        await this.getUserId(this.$route.params.id)
        await this.fetchUserId(this.userIdGetInfor)
        this.$nextTick(() => {
          this.isRender = true
        })
      }
    },
    dataUserId(val) {
      if (val) {
        this.inforUser = {
          birthDayString: this.dataUserId.birthDayString,
          phoneNumber: this.dataUserId.phoneNumber,
          address: this.dataUserId.address,
          placeOfBirth: this.dataUserId.placeOfBirth,
          academicDegree: this.dataUserId.academicDegree,
          academicRank: this.dataUserId.academicRank,
          idOrganizationStructure: this.dataUserId.idOrganizationStructure === '00000000-0000-0000-0000-000000000000' ? null : this.dataUserId.idOrganizationStructure,
          identityCardNumber: this.dataUserId.identityCardNumber,
          dateIssuedString: this.dataUserId.dateIssuedString,
          regency: this.dataUserId.regency,
          locationIssued: this.dataUserId.locationIssued,
          taxNumber: this.dataUserId.taxNumber,
          positionId: this.dataUserId.positionId,
        }
        this.accountInformation = {
          avatar: this.dataUserId.avatar,
          code: this.dataUserId.code,
          gender: this.dataUserId.gender,
          email: this.dataUserId.email,
          name: this.dataUserId.name,
          userName: this.dataUserId.userName,
          password: this.dataUserId.password,
          userTypeId: this.dataUserId.userTypeId,
          isDepartment: this.dataUserId.isDepartment,
          twoFactorEnabled: this.dataUserId.twoFactorEnabled,
        }
      }
    },

  },
  mounted() {
    this.doFetchUserTypeCombobox()
  },
  async created() {
    if (this.getRouteID()) {
      this.userId = this.getRouteID()
      this.listTabs[2].isDisabled = false
      this.isEdit = false
      if (this.unitUserId !== this.userIdLocalstorage) {
        await this.getUserId(this.$route.params.id)
        await this.fetchUserId(this.userIdGetInfor)
      }
      await this.fetchUserId(this.userId)
      this.typeCheckRole = this.$route.params.type
    }
  },
  methods: {
    // ...mapActions('users', ['doCreateUser', 'doEditUser']),
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),

    // Lấy thông tin người dùng theo id
    async fetchUserId(userId) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DATA_USER_ID}${userId}`)
      this.dataUserId = data
    },

    // Lấy thông tin id người dùng
    async getUserId(userId) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.GET_USERID}${userId}`)
      this.userIdGetInfor = data
    },

    getRouteID() {
      if (this.idEdit) {
        return this.idEdit
      } return this.$route.params.id
    },

    setActive(tabActive) {
      this.activeItem = tabActive
    },

    backUser() {
      this.$router.go(-1)
    },

    async hadnleCreate(bvModalEvt) {
      if (this.getRouteID() && this.getRouteID() !== null) {
        const data = {
          ...this.accountInformation,
          ...this.inforUser,
          id: this.typeCheckRole === 'info' ? this.userIdGetInfor : this.getRouteID(),
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.put(ConstantsApi.LIST_USERS, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Cập nhật thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })

                if (this.typeCheckRole !== 'info') this.$router.push({ name: 'list-user' })
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      } else {
        const data = {
          ...this.accountInformation,
          ...this.inforUser,
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.LIST_USERS, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                if (this.routeNamePre === '"business-add-orther"') {
                  this.$router.push({ name: 'business-add-orther' })
                  localStorage.removeItem('route-name')
                } else {
                  this.$router.push({ name: 'edit-user', params: { id: res.data?.data?.id } })
                }
                this.listTabs[2].isDisabled = false
                this.isEdit = false
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      }
    },
  },
}
</script>
