<template>
  <div>
    <b-modal
      :id="id"
      centered
      :hide-footer="true"
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      size="xl"
      title="Xem chi tiết người dùng"
    >
      <detail-user
        :hideButon="false"
        :idEdit="idEdit"
        :disableProp="true"
        :hideUserGroup="false"
      />
    </b-modal>
  </div>
</template>
<script>
import detailUser from '@/views/management-user/users/pages/Create.vue'

export default {
  components: {
    detailUser,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
    idEdit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
<style lang="scss">
.confirm-modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  .text-warning {
    svg {
      width: 80px;
      height: 80px;
    }
  }
  .title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 21px;
  }
  .action {
    margin-top: 25px;
  }
  .description {
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .confirm-modal-delete {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .confirm-modal-delete {
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .btn-left {
        margin-right: 0px !important;
      }
      .btn {
        margin-top: 10px;
      }
    }
  }
}
</style>
